<template>
  <div>
    <p>
      Elements can be categorized into a number of families on the periodic table. Determine the
      family in which each of the following elements belong:
    </p>
    <p>
      <v-container>
        <v-row v-for="i in 8" :key="i">
          <v-select v-model="inputs[`d${i}`]" :items="families">
            <template #label>
              <chemical-element-value :value="ElementArray[`${i - 1}`]" />:
            </template>
          </v-select>
        </v-row>
      </v-container>
    </p>
  </div>
</template>

<script>
import ChemicalElementValue from '@/tasks/components/ChemicalElementValue.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {VSelect} from 'vuetify/lib/components';

export default {
  name: 'QuestionDeterminingElementFamily',
  components: {VSelect, ChemicalElementValue},
  mixins: [DynamicQuestionMixin()],

  data() {
    return {
      inputs: {
        d1: null,
        d2: null,
        d3: null,
        d4: null,
        d5: null,
        d6: null,
        d7: null,
        d8: null,
      },
      families: [
        {value: 'actinoid', text: 'Actinoids'},
        {value: 'alkaliMetal', text: 'Alkali Metals'},
        {value: 'alkalineEarth', text: 'Alkaline Earth Metals'},
        {value: 'halogen', text: 'Halogens'},
        {value: 'lanthanoid', text: 'Lanthanoids'},
        {value: 'metalloid', text: 'Metalloids'},
        {value: 'nobleGas', text: 'Noble Gases'},
        {value: 'nonMetal', text: 'Non Metals'},
        {value: 'otherMetal', text: 'Other Metals'},
        {value: 'transitionMetal', text: 'Transition Metals'},
      ],
    };
  },
  computed: {
    ElementArray() {
      let eArray = [
        this.taskState.getValueBySymbol('alkalineEarth').content,
        this.taskState.getValueBySymbol('lanthanoid').content,
        this.taskState.getValueBySymbol('halogen').content,
        this.taskState.getValueBySymbol('nonMetal').content,
        this.taskState.getValueBySymbol('metalloid').content,
        this.taskState.getValueBySymbol('alkaliMetal').content,
        this.taskState.getValueBySymbol('nobleGas').content,
        this.taskState.getValueBySymbol('actinoid').content,
      ];
      return eArray;
    },
  },
};
</script>

<style scoped></style>
